var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-modal", {
        attrs: {
          id: "bulk-cancellation",
          centered: "",
          title: "Confirm Cancellation of Services",
          size: "lg",
          "no-close-on-esc": "",
          "no-close-on-backdrop": "",
        },
        scopedSlots: _vm._u([
          {
            key: "modal-footer",
            fn: function () {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "grey-button-outlined mr-2",
                    attrs: {
                      variant: "outline-secondary",
                      "aria-label": "Back to view all button",
                    },
                    on: { click: _vm.onClose },
                  },
                  [
                    _c("fa-icon", {
                      staticClass: "fa-lg mr-2",
                      attrs: { icon: "chevron-left" },
                    }),
                    _c("span", [_vm._v("Keep Services")]),
                  ],
                  1
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "custom-red-button mr-3",
                    attrs: {
                      "aria-label": "Confirm cancellation button",
                      disabled: _vm.loading,
                    },
                    on: { click: _vm.confirmCancellation },
                  },
                  [
                    _vm.loading
                      ? _c("b-spinner", { attrs: { small: "" } })
                      : _c("span", [_vm._v("Confirm Cancellation")]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "service-text" },
                  [
                    _vm.error
                      ? _c("banner", {
                          staticClass: "mb-2",
                          attrs: {
                            message:
                              "There was an issue processing your cancellation, please try again or contact customer service",
                            variant: "failed",
                          },
                        })
                      : _vm._e(),
                    _vm.savedServices.length > 0
                      ? _c("span", [
                          _vm._v(
                            "\n          You have selected to keep " +
                              _vm._s(_vm.savedServices.length) +
                              " Compliance Service and are cancelling Compliance Service and State filing for " +
                              _vm._s(_vm.selectedServices.length) +
                              " Jurisdictions\n        "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n          You are about to cancel Compliance Service and State filing for " +
                              _vm._s(_vm.selectedServices.length) +
                              " Jurisdictions\n        "
                          ),
                        ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "cancellation-info-container" }, [
                  _c("div", { staticClass: "cancellation-info-text" }, [
                    _c("h5", [
                      _vm._v(
                        "\n            Heads up! Cancelling Compliance Services will result in...\n          "
                      ),
                    ]),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "\n              You filing your own reports with the state.\n            "
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "\n              Potential fines if you miss the deadline or file incorrectly.\n            "
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "\n              One more thing you have to complete.\n            "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "service-text" }, [
                  _c("span", [
                    _vm._v(
                      "Our services are hassle free and make your life easier."
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "continue-message" }, [
                  _c("span", [_vm._v("Do you wish to continue?")]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }