<template>
  <div>
    <b-modal
      id="bulk-cancellation"
      centered
      title="Confirm Cancellation of Services"
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
    >
      <template #modal-footer class="action-buttons justify-content-end">
        <b-button
          class="grey-button-outlined mr-2"
          variant="outline-secondary"
          aria-label="Back to view all button"
          @click="onClose"
        >
          <fa-icon icon="chevron-left" class="fa-lg mr-2" />
          <span>Keep Services</span>
        </b-button>
        <b-button
          class="custom-red-button mr-3"
          aria-label="Confirm cancellation button"
          :disabled="loading"
          @click="confirmCancellation"
        >
          <b-spinner v-if="loading" small />
          <span v-else>Confirm Cancellation</span>
        </b-button>
      </template>

      <template #default>
        <div class="service-text">
          <banner
            v-if="error"
            class="mb-2"
            message="There was an issue processing your cancellation, please try again or contact customer service"
            :variant="'failed'"
          />
          <span v-if="savedServices.length > 0">
            You have selected to keep {{ savedServices.length }} Compliance Service and are cancelling Compliance Service and State filing for {{ selectedServices.length }} Jurisdictions
          </span>
          <span v-else>
            You are about to cancel Compliance Service and State filing for {{ selectedServices.length }} Jurisdictions
          </span>
        </div>
        <div class="cancellation-info-container">
          <div class="cancellation-info-text">
            <h5>
              Heads up! Cancelling Compliance Services will result in...
            </h5>
            <ul>
              <li>
                You filing your own reports with the state.
              </li>
              <li>
                Potential fines if you miss the deadline or file incorrectly.
              </li>
              <li>
                One more thing you have to complete.
              </li>
            </ul>
          </div>
        </div>
        <div class="service-text">
          <span>Our services are hassle free and make your life easier.</span>
        </div>
        <div class="continue-message">
          <span>Do you wish to continue?</span>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ConfirmBulkComplianceCancellationModal',
  components: {
    Banner: () => import('@/components/shared/Banner.vue'),
  },
  props: {
    bus: null,
  },
  data() {
    return {
      cancellationReason: null,
      error: null,
      feedback: null,
      loading: false,
      refundParams: {},
      refundTotal: 0,
      size: 'l',
    }
  },
  computed: {
    ...mapGetters('account', ['actualAccountID']),
    ...mapGetters('services', ['companyServices', 'selectedServices', 'savedServices']),
    ...mapGetters('orderItems', ['activeFilings', 'complianceFilings', 'filingsLoaded', 'refundableFilings']),
    ...mapGetters('cancellationReasons', ['allOptionsGeneric']),
  },
  mounted() {
    this.bus.$on('showBulkCancel', (cancellationReason, feedback) => {
      this.cancellationReason = cancellationReason
      this.feedback = feedback
      this.$bvModal.show('bulk-cancellation')
    })
  },
  methods: {
    ...mapActions('services', ['cancelAndRefundServicesAndFilings', 'cancelServiceAndFilings']),
    ...mapActions('invoices', ['updateRefundTotal']),
    confirmCancellation () {
      this.cancelServices()
    },
    onClose() {
      this.$bvModal.hide('bulk-cancellation')
    },
    async cancelServices() {
      this.loading = true
      this.error = null
      this.refundTotal = 0
      const uniqueCompanies = [...new Set(this.selectedServices.map(item => item.company_id))]

      const cancelPromises = uniqueCompanies.map(async (company_id) => {
        this.refundParams = []

        const companyServices = this.selectedServices.filter(x => x.company_id === company_id)
        const companyFilings = this.complianceFilings.filter(f => f.company.id === company_id)

        const companyRefundableFilings = companyFilings.filter(filing => {
          const service = companyServices.find(s => s.object_id === filing.registration_id)
          const key = `${service?.object_id}_${service?.company_id}`
          return service && this.refundableFilings[key]
        })

        let cancelResponse
        if (companyRefundableFilings.length > 0) {
          for (const filing of companyRefundableFilings) {
            this.updateRefundParams(filing)
          }

          cancelResponse = await this.cancelAndRefund(
            companyServices,
            companyFilings.filter(filing => !companyRefundableFilings.includes(filing)).map(f => f.id)
          )

          if (cancelResponse?.success, cancelResponse?.result?.response?.refund_responses) {
            const refundAmount = cancelResponse?.result?.response?.refund_responses
              .reduce((total, refund) => total + refund?.refund_response?.refund_response?.total, 0)

            this.refundTotal += refundAmount
          }
        } else {
          const params = this.cancelParams(companyServices, companyFilings)
          cancelResponse = await this.cancelServiceAndFilings({ cancel: params }).catch(error => {
            let response = error.response
            response.type = 'Cancellation'
            return response
          })
        }
        return cancelResponse
      })

      const responses = await Promise.all(cancelPromises)

      const allSuccessful = responses.every(response => response?.success)

      if (allSuccessful) {
        this.updateRefundTotal(this.refundTotal)
        this.$emit('cancel-complete')
        this.$bvModal.hide('bulk-cancellation')
      } else {
        this.error = responses.find(response => !response?.success) || true
      }

      this.$emit('refresh-cancellable-services')

      this.loading = false
    },
    async cancelAndRefund(services, order_items) {
      const params = {
        cancel: this.cancelParams(services, order_items),
        refunds: this.refundParams,
      }
      const response = await this.cancelAndRefundServicesAndFilings(params)
      return response?.data
    },
    cancelParams(services, order_items) {
      return {
          service_ids: services.map(service => service.id),
          order_item_ids: order_items,
          signature: "",
          note: null,
          cancellation_reason_detail: this.feedback,
          selected_cancellation_reason_id: this.cancellationReason,
        }
    },
    updateRefundParams(filing) {
      const reasonSelected = this.allOptionsGeneric.find(option => option.id === this.cancellationReason)
      const reason = reasonSelected?.cancellation_reason !== 'Other'
        ? `(Client Requested) ${reasonSelected?.cancellation_reason}`
        : `(Client Requested) ${this.feedback}`

      const key = `${filing?.registration_id}_${filing?.company?.id}`

      this.refundParams.push({
        order_item_ids: [filing.id],
        account_id: this.actualAccountID,
        invoice_id: this.refundableFilings[key]["id"],
        refund_reason: reason,
        signature: "",
        note: null,
        cancellation_reason_detail: this.feedback,
        selected_cancellation_reason_id: this.cancellationReason,
      })
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .modal-header {
  background-color: white !important;
  color: black !important;
}

::v-deep .modal-header .close {
  color: black !important;
  opacity: 1 !important;
}

::v-deep .modal-body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.service-text {
  padding: 1rem;
  font-weight: 400;
  font-size: 1.25rem;
}

.continue-message {
  padding: 1rem;
  font-weight: 700;
  font-size: 1.5rem;
}

.cancellation-info-container {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #F7F7FB;
  border-top: 1px solid #C0C0C0;
  border-bottom: 1px solid #C0C0C0;

  .cancellation-info-text {
    padding: 1rem;
  }
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
}

.grey-button-outlined {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #FFFFFF !important;
  border-color: #CECED2 !important;
  border-radius: 5px;
  color: #4E4E52;
  padding: .5rem 1rem .5rem 1rem
}
.grey-button-outlined:hover {
  background-color: #CDCDCD !important;
}

.custom-red-button {
  background-color: #F93F36 !important;
  border-radius: 5px;
  color: white !important;
}

.custom-red-button:hover {
  background-color: #D32F2F !important;
}

.cancel-button:hover {
  cursor: pointer;
}
</style>
